<template>

  <h4 class="py-8">Crie tipos de eventos</h4>
  <v-form @submit.prevent="addTipo">
    <v-row>
      <v-col cols="12">
        <v-text-field variant="outlined" density="compact" v-model="newTipo.name" label="Nome do grupo de Tipo" required></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-row class="pl-5" v-for="(subtipo, subIndex) in newTipo.subtipo" :key="subIndex" >
          <v-col cols="10">
            <v-text-field hide-details variant="outlined" density="compact" v-model="subtipo.name" label="Nome do tipo"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn variant="text" size="small" @click="removeNewSubtipo(subIndex)" color="error" text icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
      
        </v-row>
      </v-col>
      <v-col class="pl-4" cols="6">
        <v-btn variant="outlined" size="small" @click="addNewSubtipo" color="primary" icon><v-icon>mdi-plus</v-icon>
          <v-tooltip                
          color="primary"
          activator="parent"
          location="end"
        >Adicionar subtipo</v-tooltip>
        </v-btn>
   
      </v-col>
      <v-col cols="12" class="d-flex justify-end ">              
        <v-btn class="my-4" type="submit" height="45" border="0" color="success">Salvar Tipo</v-btn>
      </v-col>
    </v-row>
  </v-form>
<v-expansion-panels v-if="tipos.length > 0">
<v-expansion-panel v-for="(tipo, catIndex) in tipos" :key="catIndex">
<v-expansion-panel-title v-model="tipo.name">
<h4>{{ tipo.name }}</h4>
</v-expansion-panel-title>
<v-expansion-panel-text>
<v-list>
    <v-list-item-group>
      <v-list-item>
        <v-list-item-content>
          <v-text-field variant="solo-filled" v-model="tipo.name" label="Tipo" :disabled="!tipo.editing"></v-text-field>
          <v-row class="pl-5" v-for="(subtipo, subIndex) in tipo.subtipo" :key="subIndex" no-gutters>
            <v-col cols="10">
              <v-text-field variant="outlined" density="compact" v-model="subtipo.name" label="Subtipo" :disabled="!tipo.editing"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn v-if="tipo.editing" variant="text" size="small" @click="deleteSubtipo(tipo, subIndex)" color="error" text icon>
                <v-icon>mdi-delete</v-icon>
                <v-tooltip                
          color="primary"
          activator="parent"
          location="end"
        >Apagar subcategoria</v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="tipo.editing">
            <v-col cols="10">
              <v-text-field variant="outlined" density="compact" v-model="newSubtipo[tipo._id]" label="New Subtipo Name"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn size="small" @click="addSubtipo(tipo)" color="primary" text icon>
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn v-if="!tipo.editing" @click="tipo.editing = true" color="primary" class="mr-5"><v-icon>mdi-pencil</v-icon>Edit</v-btn>
          <v-btn v-else @click="saveTipo(tipo)" color="success" class="mr-5"><v-icon>mdi-floppy</v-icon>Salvar categoria</v-btn>
          <v-btn variant="outlined" @click="deleteTipo(tipo)" color="error"><v-icon>mdi-delete</v-icon>Apagar</v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list-item-group>
    
  </v-list>
</v-expansion-panel-text>

  
</v-expansion-panel>

</v-expansion-panels>

<v-alert v-else>Nenhum Tipo disponivel.</v-alert>



</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';

const tipos = ref([]);
const newTipo = ref({
name: '',
subtipo: [{ name: '' }],
});

const newSubtipo = ref({});


const fetchTipos = async () => {
try {
const response = await axios.get('https://server.matapa.org/api/tipos');
tipos.value = response.data.map(tipo => ({ ...tipo, editing: false }));
} catch (error) {
console.error('Error fetching tipos:', error);
}
};


const addTipo = async () => {
try {
const response = await axios.post('https://server.matapa.org/api/tipos', newTipo.value);
tipos.value.push({ ...response.data, editing: false });
newTipo.value = { name: '', subtipo: [{ name: '' }] };
} catch (error) {
console.error('Error adding tiype:', error);
}
};


const addNewSubtipo = () => {
newTipo.value.subtipo.push({ name: '' });
};


const removeNewSubtipo = (subIndex) => {
newTipo.value.subtipo.splice(subIndex, 1);
};


const addSubtipo = async (tipo) => {
if (!newSubtipo.value[tipo._id]) return;
tipo.subtipo.push({ name: newSubtipo.value[tipo._id] });
newSubtipo.value[tipo._id] = '';
};


const saveTipo = async (tipo) => {
try {
await axios.put(`https://server.matapa.org/api/tipos/${tipo._id}`, tipo);
tipo.editing = false;
} catch (error) {
console.error('Error saving tipo:', error);
}
};



const deleteTipo = async (id) => {
try {
await axios.delete(`https://server.matapa.org/api/tipos/${id._id}`); // Pass id._id instead of id
console.log('Types and subcategories deleted successfully');
fetchTipos(); // Refresh the list after deletion
} catch (error) {
console.error('Error deleting tipos and subcategories:', error);
}
};




const deleteSubtipo = (tipo, subIndex) => {
tipo.subtipo.splice(subIndex, 1);
};

onMounted(fetchTipos );
</script>

<style scoped>
</style>
