<template>
    <v-sheet class="mx-auto">
      <v-slide-group v-model="model"  selected-class="bg-primary">
        <v-slide-group-item
          v-for="n in 3"
          :key="n"
          v-slot="{ toggle, selectedClass }"
        >
          <v-card
            color=""
            :class="['ma-4', selectedClass]"
            height="80"
            variant="outlined"
            width="333.33"
            @click="toggle"
          >
            <v-card-title>{{ Title[n - 1] }}</v-card-title>
            <v-card-text>
              <p>{{ p[n - 1] }}</p>
            </v-card-text>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>
  
      <v-expand-transition>
        <v-sheet v-if="model === 0" height="200">
          <v-card flat class="px-4">
            <v-row>
              <v-col cols="12">
                <v-card class="pa-2"><WebsiteMatapa/></v-card>
            </v-col>
          
                 
            </v-row>
         
          </v-card>
        </v-sheet>
        <v-sheet v-if="model === 1" height="200">
          <v-card flat>
            <v-row>
              <v-col cols="12">
                <v-card class="pa-2"><WebsiteEdit/></v-card>
            </v-col>
          
                 
            </v-row>
         
          </v-card>
        </v-sheet>
        <v-sheet v-if="model === 2" height="200">
          <v-card flat>
            <v-row>
              <v-col cols="12">
                <v-card class="pa-2"><WebsiteMachavenga/></v-card>
            </v-col>
          
                 
            </v-row>
         
          </v-card>
        </v-sheet>
  
      </v-expand-transition>
    </v-sheet>
  </template>
  
  <script>
import WebsiteMatapa from '../components/WebsiteMatapa'
import WebsiteEdit from '../components/WebsiteEdit'
import WebsiteMachavenga from '../components/WebsiteMachavenga'
  
  export default {
    components: {
      WebsiteMatapa,
      WebsiteEdit,
      WebsiteMachavenga,

    },
  
    data: () => ({
      model: null,
    
      Title: ["Matapa", "Roots", "Machavenga"],
      p: [
        "Editar a página Matapa aqui",
        "Editar a página Roots aqui",
        "Edit a página Machavenga aqui",

      ],
      search: "",
      
    }),
    methods: {
      toggleItem(n) {
        this.model = n;
      },
    },
  
  };
  </script>
  